<template>
  <div class="startShopping table-hook fadeInRight">
    <div class="title-box shoppingHeader">
      <!-- <span class="m-title"> -->
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/purchaseCard/buyOfficialCard' }"
          >商店</el-breadcrumb-item
        >
        <el-breadcrumb-item>开始选购</el-breadcrumb-item>
      </el-breadcrumb>
      <!-- </span> -->
      <div @click="goToShopping" class="right cursor">
        <el-badge :value="defaultNum" class="item" type="primary">
          <i class="el-icon-shopping-cart-2"></i>
        </el-badge>
        购物车
      </div>
    </div>
    <div class="content-box">
      <div class="content-body">
        <div class="content-left">
          <div class="imgBody">
            <img :src="currentImg" alt="" style="width: 360px; height: 360px" />
          </div>
          <div class="imgfooter">
            <img
              v-for="(item, index) in imgList"
              :key="index"
              :src="item"
              alt=""
              class="imgItem"
              :class="{ isSelected: index == isActive }"
              @click="clickImageItem(index)"
            />
          </div>
        </div>
        <div class="content-right">
          <div class="right-header">
            <div class="packagediv">
              <span style="font-size: 20px !important">{{  goodItem.productName }}</span>
              <span class="operator">{{
                 _getCategory(goodItem.accountType, 1)
              
              }}</span>
              <span class="operator" v-if="goodItem.mealType == 1">
                按月套餐</span
              >
              <span class="operator" v-else-if="goodItem.mealType == 2">
                累计套餐</span
              >
            </div>
            <div class="priceDiv">
              <span style="font-size: 16px !important"
                >¥ {{  goodItem.fee? goodItem.fee.toFixed(2):goodItem.fee }}</span
              >
            </div>
          </div>
          <div class="right-content-startShopping">
            <div class="formlineOne">
              <div class="oneLeft">
                <div class="form-row_nomargin">
                  <label style="color: #666; font-weight: 700">材质</label>
                  <label class="right">
                    <el-tooltip placement="left" effect="light">
                      <div slot="content">
                        <el-tabs v-model="activeOne">
                          <el-tab-pane label="材质" name="first">
                            <div class="inline width-50">
                              <span>塑料材质：</span
                              ><span>使用温度-25℃&nbsp;~&nbsp;85℃</span>
                            </div>
                            <div
                              class="inline width-50"
                              style="margin-left: 10px"
                            >
                              <span>陶瓷材质：</span
                              ><span>使用温度-40℃&nbsp;~&nbsp;105℃</span>
                            </div>
                          </el-tab-pane>
                        </el-tabs>
                      </div>
                      <span class="iconhow"></span>
                    </el-tooltip>
                  </label>
                </div>
                <div class="form-row">
                  <div class="cailiao">
                    <el-button
                      v-for="(item, index) in punishList"
                      :key="index"
                      :class="[num == index ? 'type_tips' : '']"
                      @click="changeIndex(index, item.name, item.value)"
                      :disabled="disPunish(item.name, index)"
                      >{{ item.name }}</el-button
                    >
                  </div>
                </div>
                <div class="form-row_nomargin">
                  <label style="color: #666; font-weight: 700">规格</label>
                  <label class="right">
                    <el-tooltip placement="left" effect="light">
                      <div slot="content">
                        <el-tabs v-model="activeTwo">
                          <el-tab-pane label="规格" name="first">
                            <div class="msi-item">
                              <p class="msi-item__title">规格：</p>
                              <div class="msi-item__content">
                                <div class="inline width-50">
                                  <div class="margin-bottom20">
                                    插拔卡（MP卡）
                                  </div>
                                  <img
                                    width="200"
                                    src="https://simboss-public.oss-cn-hangzhou.aliyuncs.com/palette/static/public/images/mp-card.png"
                                  />
                                </div>
                                <div class="inline width-50 align-top">
                                  <div class="margin-bottom20">贴片卡</div>
                                  <img
                                    width="80"
                                    src="https://simboss-public.oss-cn-hangzhou.aliyuncs.com/palette/static/public/images/ms-card.png"
                                  />
                                </div>
                              </div>
                              <div class="msi-item__footer">
                                <p>
                                  注：三切卡支持以上三种规格，二切卡支持标准卡和Mcrio卡
                                </p>
                              </div>
                            </div>
                          </el-tab-pane>
                        </el-tabs>
                      </div>
                      <span class="iconhow"></span>
                    </el-tooltip>
                  </label>
                </div>
                <div class="form-row">
                  <div class="cailiao">
                    <el-button
                      v-for="(item, index) in standardList"
                      :key="index"
                      :class="[num1 == index ? 'type_tips' : '']"
                      @click="changeIndexOne(index, item.name, item.value)"
                      :disabled="disStandard(item.name, index)"
                      >{{ item.name }}</el-button
                    >
                  </div>
                </div>
                <div class="color-666 form-row" style="margin-top: 15px">
                  <span v-if="cardFee===''"></span>
                  <span v-else>{{ "卡费："+cardFee+"元/张" }}</span>
                </div>
              </div>
              <div class="oneLeft" style="margin-left: 20px">
                <div class="form-row_nomargin">
                  <label style="color: #666; font-weight: 700">套餐分类</label>
                </div>
                <div class="form-row" style="height: 210px; overflow-y: auto">
                  <div class="cailiao">
                    <!-- <el-button
                      :class="[num2 == 1 ? 'type_tips' : '']"
                      @click="changeIndexTwo(num2)"
                      >{{ availableObj.mealName }}</el-button
                    > -->
                    <el-button
                      v-for="(item, index) in mealNameList"
                      :key="index"
                      :class="[num2 == index ? 'type_tips' : '']"
                     
                      @click="changeIndexTwo(index, item)"
                      >{{ item.name }}</el-button
                    >

                  </div>
                </div>
                <div class="form-row_nomargin">
                  <label style="color: #666; font-weight: 700">网络模式</label>
                </div>
                <div class="form-row">
                  <div class="cailiao multiple">
                    <el-button
                      v-for="(item, index) in netModellList"
                      :key="index"
                      :class="{
                        type_tips: form.netModel.includes(item.value),
                      }"
                      :disabled="disNetmode(item.value)"
                      @click="changeIndexThree(index, item)"
                      >{{ item.name }} <span class="badge-s"> </span
                    ></el-button>
                  </div>
                  <!-- <div class="intent_btn">
  <van-button style="width: .90rem;" v-for="(item, index) in netModellList" :key="index" :class="item.ischeck == true ? 'btn_selected' : ''"
  size="medium" @click="buttonSelectOne(index, item, List)">{{item.name}}</van-button>
</div> -->
                </div>
              </div>
            </div>
            <div class="formlineTwo">
              <div class="oneLeft">
                <div class="form-row_nomargin">
                  <label style="color: #666; font-weight: 700">卡片功能</label>
                  <label class="right">
                    <el-tooltip placement="left" effect="light">
                      <div slot="content">
                        <el-tabs v-model="activeThree">
                          <el-tab-pane label="流量池" name="first">
                            <p class="f-size-l9 margin-bottom40">
                              <span
                                >流量池：物联网卡可设置流量池，加入对应流量池，池内单张卡使用流量不受套餐限制，所有卡使用总量不超过流量池总量即可，为公司有效节省成本。</span
                              >
                              <!-- <a
                                target="_blank"
                                href="https://simboss.com/www/announcement?id=150650315689188"
                                class="link"
                                >查看详情</a
                              > -->
                            </p>
                            <!-- <p class="f-size-l9 color-666 margin-bottom20">
                              例：现有两张物联网卡，套餐均为1G/月，情况如下：
                            </p>
                            <div >
                              <div class="inline" style="width:49%">
                                <p class="f-size-l9 color-333">未开通流量池</p>
                                <span class="margin-bottom20 inline  f-size-l9 color-666" 
                                  >套餐流量仅限单卡使用，单张物联网卡使用超过1G流量即停卡。</span
                                ><img
                                  width="200"
                                  style="display:block"
                                  src="https://simboss-public.oss-cn-hangzhou.aliyuncs.com/palette/static/public/images/volume-pool-not.png"
                                />
                              </div>
                              <span></span>
                              <div class="inline  align-top" style="width:49%">
                                <p class="f-size-l9 color-333">开通流量池</p>
                                <span
                                  class="
                                    inline
                                    margin-bottom20
                                    f-size-l9
                                    color-666
                                  "
                                  >单张物联网卡可使用超过1G流量，两张卡使用超过2G流量后，均停卡。</span
                                ><img 
                                  width="200"
                                  src="https://simboss-public.oss-cn-hangzhou.aliyuncs.com/palette/static/public/images/volume-pool-sharable.png"
                                />
                              </div>
                            </div> -->
                          </el-tab-pane>
                          <!-- <el-tab-pane label="短信服务" name="second">
                            <p class="f-size-l9 margin-bottom40">
                              <span
                                >短信服务：开通短信根据不同运营商收取不同费用；发送短信收费，接收短信不收费</span
                              >
                            </p>
                            <div class="align-center">
                              <img
                                width="450"
                                src="https://simboss-public.oss-cn-hangzhou.aliyuncs.com/palette/static/public/images/support-sms.png"
                              />
                            </div>
                            <p class="f-size-l9 color-666 margin-top40">
                              注：短信发送/接收受物联网号段（11/13位卡）、不同收发对象（平台、物联网卡、手机）影响，视具体情况而定。
                            </p>
                          </el-tab-pane> -->
                        </el-tabs>
                      </div>
                      <span class="iconhow"></span>
                    </el-tooltip>
                  </label>
                </div>
                <div class="form-row">
                  <div class="cailiao multiple">
                    <el-button
                      v-for="(item, index) in cardFuncList"
                      :key="index"
                      :class="{
                        type_tips: form.cardFunc.includes(item.value),
                      }"
                      :disabled="disCardfunction(item.value)"
                      @click="changeIndexFour(index, item)"
                      >{{ item.name }} <span class="badge-s"> </span
                    ></el-button>
                  </div>
                </div>
              </div>
              <div class="oneLeft" style="margin-left: 20px;display:none;">
                <div class="form-row_nomargin">
                  <label style="color: #666; font-weight: 700"
                    >网络增值服务</label
                  >
                  <label class="right">
                    <el-tooltip placement="left" effect="light">
                      <div slot="content">
                        <el-tabs v-model="activeFour">
                          <el-tab-pane label="白名单" name="first">
                            <p class="f-size-l9 margin-bottom40">
                              <span
                                >白名单：是精细化的上网应用管理。VPDN卡设置白名单后，终端只能访问白名单内包含的应用或者网站，其它所有应用和网站都被拒绝访问。</span
                              >
                            </p>
                            <p class="align-center">
                              <img
                                src="https://simboss-public.oss-cn-hangzhou.aliyuncs.com/palette/static/public/images/network-baimingdan.png"
                                style="width:80%"
                              />
                            </p>
                            <br />
                            <p>注：最多支持5个白名单地址</p>
                          </el-tab-pane>
                          <el-tab-pane label="限速" name="second">
                            <p class="f-size-l9 margin-bottom40">
                              <span
                                >限速：可以在VPDN卡达到预定的流量档位后限制成较低的上网速度，从而实现对上网应用的管理，既能保证低速率应用的上网需求，又能避免流量用于高速率应用中。</span
                              >
                            </p>
                            <p class="align-center">
                              <img
                                src="https://simboss-public.oss-cn-hangzhou.aliyuncs.com/palette/static/public/images/network-xiansu.png"
                                class="width-80" style="width:80%"
                              />
                            </p>
                          </el-tab-pane>

                          <!-- <el-tab-pane label="分应用计费" name="third">
                            <p class="f-size-l9 margin-bottom40">
                              <span
                                >分应用计费：对VPDN卡访问的不同应用或服务，设置不同的子套餐(关键数据，音频，视频等)，分别进行计费并进行相应的上网控制。</span
                              >
                            </p>
                            <p class="align-center">
                              <img
                                src="https://simboss-public.oss-cn-hangzhou.aliyuncs.com/palette/static/public/images/network-fenyingyong.png"
                                class="width-80"
                              />
                            </p>
                          </el-tab-pane>
                          <el-tab-pane label="企业专网" name="four">
                            <p class="f-size-l9 margin-bottom40">
                              <span
                                >企业专网：将物联网与企业内部网络连通，将物联网的数据直接送到企业内部网络，并实现物联网和企业网络之间的双向通信。</span
                              >
                            </p>
                            <p class="align-center">
                              <img
                                src="https://simboss-public.oss-cn-hangzhou.aliyuncs.com/palette/static/public/images/network-qiyezhuanwang.png"
                                class="width-80"
                              />
                            </p>
                          </el-tab-pane>
                          <el-tab-pane label="卡卡自组网" name="five">
                            <p class="f-size-l9 margin-bottom40">
                              <span
                                >卡卡自组网：在物联网卡与卡之间(即终端设备之间)组建一张网络，实现卡与卡，设备与设备之间的互相通讯，组网灵活，简单使用，适用于各种联网需求。</span
                              >
                            </p>
                            <p class="align-center">
                              <img
                                src="https://simboss-public.oss-cn-hangzhou.aliyuncs.com/palette/static/public/images/network-kaka.png"
                                class="width-80"
                              />
                            </p>
                          </el-tab-pane> -->
                        </el-tabs>
                      </div>
                      <span class="iconhow"></span>
                    </el-tooltip>
                  </label>
                </div>
                <div class="form-row">
                  <div class="cailiao">
                    <el-button
                      v-for="(item, index) in netServicelList"
                      :key="index"
                      :class="[num3 == index ? 'type_tips' : '']"
                      :disabled="disNetService(item.value)"
                      @click="changeIndexFive(index, item.value)"
                      >{{ item.name }}</el-button
                    >
                  </div>
                </div>
              </div>
            </div>
            <el-form ref="formOne" :model="formOne">
              <el-form-item label="购卡" class="formOneLabel"> </el-form-item>
              <el-form-item label="" class="formOneContent">
                <span class="left">
                  <el-form-item label="起购周期">
                    <el-input
                      :disabled="true"
                      v-model="availableObj.leastBuyCount"
                    ></el-input>
                    <span class="margin-5">月</span>
                  </el-form-item>
                  <el-form-item label="沉默期" prop="silentCount">
                    <el-select
                      v-model="formOne.silentCount"
                      placeholder="请选择"
                      clearable
                    >
                      <el-option label="0" value="0"></el-option>
                      <el-option label="3" value="3"></el-option>
                    </el-select>
                    <span class="margin-5">月</span>
                  </el-form-item>
                   	<el-form-item label="客户">
					<el-select v-model="formOne.agentId" placeholder="客户" clearable filterable >
						<el-option label="全部" value=""></el-option>
						<el-option v-for="(item) in customerList" :key="item.id" :label="item.name" :value="item.id"></el-option>
					</el-select>
		</el-form-item>
                  
                </span>
                <span class="right">
                  <el-form-item label="总周期" prop="subscribeCount">
                    <el-input-number
                      v-model="formOne.subscribeCount"
                      :min="subscribeCountstart"
                      :max="subscribeCountend"
                      :step="availableObj.leastBuyCount"
                      step-strictly
                    ></el-input-number>
                    <span class="margin-5">月</span>
                  </el-form-item>
                  <el-form-item label="卡片数量" prop="cardCount">
                    <el-input-number
                      v-model="formOne.cardCount"
                      :min="1"
                      :max="1000000"
                      :step="1"
                      label=""
                    ></el-input-number>
                    <span class="margin-5">张</span>
                    <p>起订量1张，步长1张</p>
                  </el-form-item>
                </span>
              </el-form-item>
              <el-form-item label="">
                <el-button type="warning" class="warning"  @click="addToShopping"
                  >加入购物车</el-button
                >
                <!-- <el-button type="warning" class="warning"  @click="rightToBuy"
                  >立即购买</el-button
                > -->
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
      <div class="content-body-down">
        <el-tabs v-model="activeName">
          <el-tab-pane label="详细介绍" name="first" v-html="getDetailHtml">
            <div class="bodyDowndetail">
              <div class="bodyDowndetailLeft">
                <div class="bodyDowndetailItem">
                  <label>实名认证：</label><span>企业实名</span>
                </div>
                <div class="bodyDowndetailItem">
                  <label>更换套餐：</label><span>不支持</span>
                </div>
                <div class="bodyDowndetailItem">
                  <label>续加油包：</label><span>不支持</span>
                </div>
                <div class="bodyDowndetailItem">
                  <label>续费期限：</label><span>无时间限制</span>
                </div>
              </div>

              <div class="bodyDowndetailLeft">
                <div class="bodyDowndetailItem">
                  <label>流量有效期：</label><span>首次使用立即生效，3月 (3 * 30天)内有效</span>
                </div>
                <div>
                  注：为与运营商侧统一，自定义套餐月份为固定30天(非自然月)
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="常见问题" name="second" v-html="getQAHtml">
            <div class="bodyDownProblem">
              <div class="bodyDowndetailItem">
                <p class="desc_qus">物联网卡是否支持4G网络？</p>
                <p class="desc_ans">三网运营商的物联网卡均支持4G网络；</p>
              </div>
              <div class="bodyDowndetailItem">
                <p class="desc_qus">物联网卡怎么激活使用？</p>
                <p class="desc_ans">物联网卡放入通电设备后就会自动激活； 若物联网卡开启了实名认证，则需要完成实名认证后才能正常使用； 部分物联网卡需要设置APN，请在使用前按照说明设置APN值；</p>
              </div>
              <div class="bodyDowndetailItem">
                <p class="desc_qus">物联网卡能否更换设备进行使用？</p>
                <p class="desc_ans">三网运营商的物联网卡均含有机卡绑定限制，不支持更换设备使用；</p>
              </div>
              <div class="bodyDowndetailItem">
                <p class="desc_qus">物联网卡有测试期和沉默期么？</p>
                <p class="desc_ans">三网运营商的物联网卡均无测试期，购买的卡均提供3个月的沉默期（即最晚激活时间）；</p>
              </div>
            </div>
           </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import {
  availableList,
  allChoiceList,
  meterialMixList,
  addCar,
  getQAList,
  addByAgent 
} from "@/api/onlinePurchase.js";
import {
 enterpriseList
} from "@/api/partner.js";


import { messageBox, confirmBox } from "@/utils/common.js";
import resize from "@/mixins/resize.js";
import dictGroup from "@/mixins/dictGroup.js";
export default {
  name: "startShopping",
  mixins: [resize, dictGroup],
  data() {
    return {
      defaultNum: "",
      imgList: [
      
      ],
      currentImg: "", //显示当前查看的大图
      isActive: false,
      activeName: "first",
      activeOne: "first",
      activeTwo: "first",
      activeThree: "first",
      activeFour: "first",
      punishList: [],
      standardList: [],
      netServicelList: [],
      netModellList: [],
      cardFuncList: [],
      meterialMixList: [], //可用商品列表
      num: 100,
      num1: 100,
      num2: 100,
      num3: 100,
      form: {
        netModel: [],
        spec: "",
        material: "",
        cardFunc: [],
        taocan: "",
        netService: "",
        mealId:""
      },
      formOne: {
        silentCount: "0",
        subscribeCount: 0,
        cardCount: "1",
        agentId:"",
      },
      goodItem: this.$route.query.goodItem ,
      availableObj: {},
      // flowPermonth: this.$route.query.flowPermonth,
      lists: [], //过滤后材料规格组合
      currentCZ: "", //当前选中的材质
      currentGG: "", //当前选中的规格
      subscribeCountstart: 0,
      subscribeCountend: 12,
      formName:{
      netModelStr:[],
        cardFuncStr:[],
      },
       getDetailHtml:"",
        getQAHtml:'',
        cardFee:"",
        mealNameList:[],
         customerList:[],
    };
  },
  created() {
    console.log((this.goodItem) );
    this.goodItem = JSON.parse(decodeURIComponent(this.$route.query.goodItem))
    if(this.goodItem.mealType==1){
         this.formName.mealTypeStr= "按月"
    }else if(this.goodItem.mealType==2){
       this.formName.mealTypeStr= "累计"
    }
    this.getQAList();
    allChoiceList().then((res) => {
      if (JSON.stringify(res.netModelMap) == "{}") {
      } else {
        this.netModellList = [];
        for (let i in res.netModelMap) {
          this.netModellList.push({ value: i, name: res.netModelMap[i] });
        }
      }
      if (JSON.stringify(res.cardFuncMap) == "{}") {
        this.cardFuncList = [{ value: "", name: "无" }];
      } else {
        this.cardFuncList = [{ value: "", name: "无" }];
        for (let i in res.cardFuncMap) {
          this.cardFuncList.push({ value: i, name: res.cardFuncMap[i] });
        }
      }
      if (JSON.stringify(res.netServiceMap) == "{}") {
        this.netServicelList = [{ value: "", name: "无" }];
      } else {
        this.netServicelList = [{ value: "", name: "无" }];
        for (let i in res.netServiceMap) {
          // this.netServicelList.push( res.netServiceMap[i] );
          this.netServicelList.push({ value: i, name: res.netServiceMap[i] });
        }
      }
      if (JSON.stringify(res.materialMap) == "{}") {
        this.punishList = [];
      } else {
        this.punishList = [];
        for (let i in res.materialMap) {
          this.punishList.push({ value: i, name: res.materialMap[i] });
        }
      }
      if (JSON.stringify(res.specMap) == "{}") {
        this.standardList = [];
      } else {
        this.standardList = [];
        for (let i in res.specMap) {
          this.standardList.push({ value: i, name: res.specMap[i] });
        }
      }
    });
    this.getAvailableList();
    meterialMixList().then((res) => {
      this.meterialMixList = res;
    });
    setTimeout(() => {
      this.getList();
    }, 2000);
   this.getEnterpriseList()
     try{
               console.log("诺行版权")
            }catch(error){
               console.log("notioni:"+error)
            }
  },

  methods: {
        getEnterpriseList() {
      let params = {
        pageSize: 100000,
        pageNo: 1,
      };
     
      enterpriseList(params).then((res) => {
        this.customerList = res.rows;
      });
    },
     getQAList() {
      getQAList({ question: "开始购物详细介绍" }).then((res) => {
        console.log(res.rows);
        this.getDetailHtml =res.rows[0].answer;
      });
      getQAList({ question: "开始购物常见问题" }).then((res) => {
        console.log(res.rows[0].answer);
        this.getQAHtml =res.rows[0].answer;
      });
    },
    disStandard(name, index) {
      let standarr = [];
      if (this.currentCZ) {
        standarr = this.lists.filter((res) => {
          return res[0].materialStr == this.currentCZ;
        });

        if (standarr.length != 0) {
          return !JSON.stringify(standarr).includes(name);
        } else return true;
      } else {
        return false;
      }
    },
    disPunish(name, index) {
      let standarr = [];

      if (this.currentGG) {
        standarr = this.lists.filter((res) => {
          return res[0].specStr == this.currentGG;
        });

        if (standarr.length != 0) {
          return !JSON.stringify(standarr).includes(name);
        } else return true;
      } else {
        return false;
      }
    },
    disNetmode(id) {
      // console.log(this.availableObj.netModel)
      // console.log(JSON.parse(this.availableObj.netModel))
      if(this.availableObj.netModel){
         return !JSON.parse(this.availableObj.netModel).includes(id);
      }
     
    },
    disCardfunction(id) {
      if (id === "") {
        return false;
      } else {
        if(this.availableObj.cardFunc){
          return !JSON.parse(this.availableObj.cardFunc).includes(id);
        }
        
      }
    },
    disNetService(id) {
      if (id === "") {
        return false;
      } else {
        if(this.availableObj.netService){
return !JSON.parse(this.availableObj.netService).includes(id);
        }
        
      }
    },
    getAvailableList() {
      console.log(this.goodItem)
      availableList({ id: this.goodItem.choiceId }).then((res) => {
        this.availableObj = res;
        // console.log(res.images);
        if (res.images) {
          this.imgList = JSON.parse(res.images);
          this.currentImg = this.imgList[0];
        }
        if (this.availableObj.leastBuyCount == 1) {
          this.subscribeCountstart = 3;
          this.subscribeCountend = 12;
        } else {
          this.subscribeCountstart = this.availableObj.leastBuyCount;
          this.subscribeCountend = this.availableObj.leastBuyCount * 10;
        }
        console.log(this.availableObj);
        this.mealNameList=[]
        if(res.mealIds){
JSON.parse(res.mealIds).forEach((item,index)=>{
       this.mealNameList.push({id:item,name:JSON.parse(res.mealName)[index]})
       })
        }
       
        console.log(this.mealNameList)
      });
    },
    goToShopping() {
      //购物车界面
      this.$router.push({ path: "/purchaseCard/shoppingCart" });
    },
    addToShopping() {
       if (this.form.material == "") {
        this.$alert("请选择材质", "提示", {
          confirmButtonText: "确定",
        });
        return;
      }
     

      if (this.form.spec == "") {
        this.$alert("请选择规格", "提示", {
          confirmButtonText: "确定",
        });
        return;
      }
     
      if (this.form.taocan == "") {
        this.$alert("请选择套餐", "提示", {
          confirmButtonText: "确定",
        });
        return;
      }
      if (this.form.netModel.length == 0) {
        this.$alert("请选择网络模式", "提示", {
          confirmButtonText: "确定",
        });
        return;
      }
       // console.log(this.formOne.subscribeCount)
      if (this.formOne.subscribeCount == 0) {
        this.$alert("请选择总周期", "提示", {
          confirmButtonText: "确定",
        });
        return;
      }

       if (this.formOne.agentId == "") {
        this.$alert("请选择客户", "提示", {
          confirmButtonText: "确定",
        });
        return;
      }
       
        let params = {
          productId:this.goodItem.id,
          material:this.form.material,
          spec:this.form.spec,//
          netModel:JSON.stringify(this.form.netModel),//多选
          cardFunc:JSON.stringify(this.form.cardFunc),//多选
          netService:this.form.netService,
          subscribeCount:this.formOne.subscribeCount,
          silentCount:this.formOne.silentCount,
          cardCount:this.formOne.cardCount,
          imageUrl:this.currentImg,
          mealId:this.form.mealId,
          agentId:this.formOne.agentId,
        }
        


        addByAgent(params).then(res=>{
            this.$confirm('已成功加入购物车?', '提示', {
          confirmButtonText: '继续购卡',
          cancelButtonText: '查看购物车',
          type: 'warning'
        }).then(() => {
          this.$router.push({ path: "/purchaseCard/buyOfficialCard" });
         
        }).catch(() => {
            this.$router.push({ path: "/purchaseCard/shoppingCart" });        
        });
        })

    },
    rightToBuy() {
  if (this.form.material == "") {
        this.$alert("请选择材质", "提示", {
          confirmButtonText: "确定",
        });
        return;
      }
     

      if (this.form.spec == "") {
        this.$alert("请选择规格", "提示", {
          confirmButtonText: "确定",
        });
        return;
      }
     
      if (this.form.taocan == "") {
        this.$alert("请选择套餐", "提示", {
          confirmButtonText: "确定",
        });
        return;
      }
      if (this.form.netModel.length == 0) {
        this.$alert("请选择网络模式", "提示", {
          confirmButtonText: "确定",
        });
        return;
      }
       // console.log(this.formOne.subscribeCount)
      if (this.formOne.subscribeCount == 0) {
        this.$alert("请选择总周期", "提示", {
          confirmButtonText: "确定",
        });
        return;
      }
      let paramsOne= {
        productName:this.$route.query.goodItem.productName,
        accountType:this.$route.query.goodItem.accountType,
        mealTypeStr:this.formName.mealTypeStr,//套餐类型 累计
        materialStr:this.formName.materialStr,
        specStr:this.formName.specStr,
        netModelStr:"",
        cardFuncStr:"",
        mealName:this.form.taocan,
        autoRenew:"",
        mealPrice:this.goodItem.fee,
        subscribeCount:this.formOne.subscribeCount,
        silentCount:this.formOne.silentCount,
        // singleFee:this.availableObj.fee,
        cardCount:this.formOne.cardCount,
        totalFee:"",
      }
      this.formName.netModelStr.forEach(item=>{
         paramsOne.netModelStr+=item+" "
      })
      this.formName.cardFuncStr.forEach(item=>{
         paramsOne.cardFuncStr+=item+" "
      })
    
  let m =   this.meterialMixList.filter(item=>{
            console.log(item.materialStr == this.formName.materialStr,item.specStr == this.formName.specStr)
      return ( item.materialStr == this.formName.materialStr && item.specStr == this.formName.specStr)
              
      })
      paramsOne.singleFee= m[0].fee
     paramsOne.totalFee   = ((parseInt(this.formOne.subscribeCount) /parseInt(this.availableObj.leastBuyCount))*parseFloat(this.goodItem.fee)+ parseFloat( paramsOne.singleFee )) *parseInt(this.formOne.cardCount) 
    console.log(paramsOne)
       let params = {
          productId:this.goodItem.id,
          material:this.form.material,
          spec:this.form.spec,//
          netModel:JSON.stringify(this.form.netModel),//多选
          cardFunc:JSON.stringify(this.form.cardFunc),//多选
          netService:this.form.netService,
          subscribeCount:this.formOne.subscribeCount,
          silentCount:this.formOne.silentCount,
          cardCount:this.formOne.cardCount,
          mealId:this.form.mealId,
        }
        this.$router.push({ path: "/shopping/submitOrder",query:{buyCarItems:params,otherDetail:paramsOne} });

    },
    getClasses(index) {
      if (index == 0) {
        return { isSelecter: true };
      } else {
        return { isSelecter: false };
      }
    },
    clickImageItem(index) {
      this.currentImg = this.imgList[index];
      this.isActive = index;
    },
    changeIndex(index, name, value) {
      //改变材质
      if (this.num != index) {
        this.num = index;
        this.currentCZ = name;
        this.form.material = value;
         this.formName.materialStr = name;
          this.lists.forEach(item=>{
         if(item[0].spec==this.form.spec&&item[0].material==this.form.material){
             this.cardFee=item[0].fee
         }
        })
      } else {
        this.num = 100;
        this.form.material = "";
         this.formName.materialStr = '';
         this.cardFee=""
      }
    },
    getList() {
      this.lists = [];
      if (this.availableObj.materialSpec) {
        JSON.parse(this.availableObj.materialSpec).forEach((items) => {
          this.lists.push(
            this.meterialMixList.filter((item) => {
              return item.id == items;
            })
          );
        });
        console.log(this.lists)
      }
    },
    changeIndexOne(index, name,value) {
      //改变规格
      if (this.num1 != index) {
        this.num1 = index;
        this.currentGG = name;
        this.form.spec = value;
        this.formName.specStr=name
        this.lists.forEach(item=>{
         if(item[0].spec==this.form.spec&&item[0].material==this.form.material){
             this.cardFee=item[0].fee
         }
        })

      } else {
        this.num1 = 100;
        this.form.spec = "";
        this.formName.specStr=""
        this.cardFee =""
      }
    },
    changeIndexTwo(index,item) {
      //改变套餐
      // if (index == 1) {
      //   this.num2 = 100;
      //   this.form.taocan = "";
      //   this.form.mealId= ""
      // } else {
      //   this.num2 = 1;
      //   this.form.taocan = this.availableObj.mealName;
      //   this.form.mealId= this.availableObj.mealId;
      // }
        if (this.num2 != index) {
        this.num2 = index;
        this.form.mealId = item.id;
        this.form.taocan = item.name;
      } else {
        this.num2 = 100;
          this.form.mealId = "";
        this.form.taocan = "";
      }
    },
    changeIndexThree(index, item) {
      //改变网络模式
      if (this.form.netModel.includes(item.value)) {
        // 反选的
        this.form.netModel = this.form.netModel.filter(function (ele) {
          return ele != item.value;
        });
      } else {
        // 选中的
        this.form.netModel.push(item.value);
      }
       if (this.formName.netModelStr.includes(item.name)) {
        // 反选的
        this.formName.netModelStr = this.formName.netModelStr.filter(function (ele) {
          return ele != item.name;
        });
      } else {
        // 选中的
        this.formName.netModelStr.push(item.name);
      }
     
    },
    changeIndexFour(index, item) {
      //改变卡片功能

      if (this.form.cardFunc.includes(item.value)) {
        // 反选的
        this.form.cardFunc = this.form.cardFunc.filter(function (ele) {
          return ele != item.value;
        });
      } else {
        // 选中的
        this.form.cardFunc.push(item.value);
        if (item.value == "") {
          this.form.cardFunc = [""];
        } else {
          this.form.cardFunc = this.form.cardFunc.filter((item) => item !== "");
        }
      }
       if (this.formName.cardFuncStr.includes(item.name)) {
        // 反选的
        this.formName.cardFuncStr = this.formName.cardFuncStr.filter(function (ele) {
          return ele != item.name;
        });
      } else {
        // 选中的
        this.formName.cardFuncStr.push(item.name);
        if (item.name == "无") {
          this.formName.cardFuncStr = [""];
        } else {
          this.formName.cardFuncStr = this.formName.cardFuncStr.filter((item) => item !== "无");
        }
      }

      // console.log(this.form.cardFunc);
    },
    changeIndexFive(index, value) {
      if (this.num3 != index) {
        this.num3 = index;
        this.form.netService = value;
      } else {
        this.num3 = 100;
        this.form.netService = "";
      }
      // console.log(this.form.netService);
    },
  },
};
</script>
<style >
.startShopping  .cursor{cursor: pointer;}
.startShopping .el-input.el-input--small.is-disabled {
  width: 91%;
}
.startShopping .cailiao .el-button {
  overflow: hidden;
  position: relative;
  margin-right: 10px;
  margin-top: 10px;
  margin-left: 0 !important;
}
.startShopping .cailiao .el-button.is-disabled {
  border-color: #ebeef5 !important;
  color: #c0c4cc !important;
}
.startShopping .title-box .el-breadcrumb {
  float: left;
  line-height: 40px;
}
.startShopping .content-box {
  position: static;
}
.startShopping .content-body-down {
  margin: 10px;
  background-color: #fff;
  min-height: 400px;
}
.startShopping .content-body {
  display: flex;
  padding: 20px 30px;
  border-bottom: 1px solid #ebebeb;
  overflow: auto;
}
.startShopping .shoppingHeader {
  margin-bottom: 10px;
}
.startShopping .imgfooter {
  width: 360px;
  align-items: center;
  text-align: center;
}
.startShopping .imgItem {
  width: 40px;
  height: 40px;
  border: 1px solid #ebebeb;
  cursor: pointer;
}
.startShopping .content-right {
  flex-grow: 1;
  padding-left: 70px;
  padding-right: 10px;
}
.startShopping .isSelected {
  border: 1px solid #20a0ff;
}
.startShopping .right-header {
  border-bottom: 1px solid #ebebeb;
  padding-bottom: 10px;
}

.startShopping .content-right .right-header .packagediv {
  font-size: 16px !important;
  margin-bottom: 20px !important;
  color: #333;
}
.startShopping .priceDiv {
  font-size: 16px !important;
  font-weight: 500;
}
.startShopping .content-right .right-header .packagediv .operator {
  font-size: 14px !important;
  color: #666;
  margin-left: 5px;
}
.startShopping .bodyDowndetail {
  padding: 20px 90px;
  color: #666;
  display: flex;
}
.startShopping .bodyDownProblem {
  padding: 20px 90px;
  color: #666;
}
.startShopping .bodyDowndetailLeft {
  flex: 1;
}
.startShopping .el-tabs__nav-scroll {
  display: flex !important;
  justify-content: center !important;
}
.startShopping .bodyDowndetailItem {
  margin-bottom: 20px;
}
.startShopping .desc_qus:before {
  content: "Q:";
  margin-right: 10px;
  color: #20a0ff;
}
.desc_ans:before {
  content: "A:";
  margin-right: 10px;
  color: #20a0ff;
}
.startShopping .formlineOne {
  display: flex;
  justify-content: flex-start;
}
.startShopping .formlineTwo {
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
}
.startShopping .oneLeft {
  width: 320px;
  height: 320px;
  padding: 10px;
  border: 1px solid #ebebeb;
}
.startShopping .form-row_nomargin {
  margin-bottom: 0;
}
.startShopping .form-row {
  margin-bottom: 15px;
}
.startShopping .width-50 {
  width: 50%!important;
}
.iconhow:before {
  content: "?";
  border-radius: 50%;
  background-color: rgb(102 102 102);
  display: block;
  width: 15px;
  height: 15px;
  text-align: center;
  line-height: 15px;
  color: #fff;
  font-size: 8px;
  font-weight: 600;
}
.startShopping .type {
  background: #f0f0f0;
  color: #7c7c7c;
}
.startShopping .el-button.type_tips:focus,
.startShopping .el-button.type_tips:hover {
  color: #20a0ff !important;
  border-color: #20a0ff !important;
}
.startShopping .type_tips {
  color: #20a0ff !important;
  border-color: #20a0ff !important;
}
.startShopping .el-button:focus,
.startShopping .el-button:hover {
  color: #333;
  border: 1px solid #c4c4c4;
  background-color: #fff;
}
.startShopping .align-top {
  vertical-align: top !important;
}
.cailiao.multiple .badge-s {
  position: absolute;
  width: 20px;
  height: 30px;
  right: -7px;
  bottom: -14px;
  transform: rotate(45deg);
  background: #ccc;
}
.cailiao.multiple .badge-s:before {
  content: "";
  position: absolute;
  width: 4px;
  height: 7px;
  right: 13px;
  bottom: 12px;
  transform: rotate(-4deg);
  border-color: transparent #fff #fff transparent;
  border-style: solid;
  border-width: 1px;
}
.cailiao.multiple .type_tips .badge-s {
  background: #20a0ff;
}
.startShopping .margin-bottom20 {
  margin-bottom: 20px;
}
.startShopping .right-content-startShopping {
  margin-top: 15px;
}
.startShopping .formOneContent {
  width: 700px;
  margin-bottom: 10px;
}
.formOneContent .left .el-form-item__content {
  margin-left: 90px;
}
.formOneContent .right .el-form-item__content {
  margin-left: 90px;
}
.formOneContent .el-form-item .el-form-item__label {
  width: 90px;
  text-align: left;
}
.formOneContent .margin-5 {
  margin-left: 5px;
}
.formOneLabel .el-form-item__label {
  font-size: 14px;
  font-weight: bold;
  margin-top: 20px;
}
.startShopping  .inline {
    display: inline-block!important;
}
.el-tooltip__popper  .f-size-l9 {
   font-size: 12px!important;
}
 .width-80 {
    width: 80%!important;
}

</style>
